import React from 'react'
import { Navigate } from 'react-router-dom'
import Loading from "./Loading";

const ProtectedRoute = ({ auth, component: Component }) => {
  const { isAuthenticated, isLoading } = auth
  if (isLoading) return <Loading />
  if (!isAuthenticated) {
    return <Navigate to="/" />;
  }

  return <Component />;

}

export default ProtectedRoute
