import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button } from "primereact/button";
import backgroundImage from "../assets/backgroundImage.jpg";
import "./LoginButton.css"

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/",
      },
    });
  };

  return (
    <div style={{ position: "relative", background: "rgba(0, 0, 0, 0.55)" }}>
      <div
        style={{ backgroundImage: `url(${backgroundImage})` }}
        className="login-background"
      ></div>

      <div className="login-content">
        <h1>StockTracker</h1>
        <div>
          <Button
            label="Login"
            icon="pi pi-user"
            severity="secondary"
            raised
            size="large"
            onClick={handleLogin}
          ></Button>
        </div>
      </div>
    </div>
  );
};

export default LoginButton;
