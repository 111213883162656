// import moment from "moment";
const moment = require('moment');

const today = moment();
export default function calculateVDCAStock(filterVDCAResult) {

    if (filterVDCAResult) {
        const calVDCAStock = filterVDCAResult.map(v => {
            let 打劫價調整; //=IF([@股息除淨日]>TODAY(),[@打劫價],[@打劫價]-[@股息2])
            let netCash; //=IFERROR(([@現金]-[@TL])/[@[股數(股)]]*[@單位],0)
            let 距離打劫價 //=ABS(([@打劫價]-[@現價])/[@現價])
            let 市值_億; //[#"市值(港元)"]/100000000
            let 股息Present // =[@股息2]/[@[現價 (Excel)]]
            // let PSScore //=VLOOKUP([@股票編號],Get_Stock_Info,COLUMN(Get_Stock_Info[[#標題],[PS Score 總分 (滿分9分)]]), FALSE)
            // let SamPoints //=VLOOKUP([@股票編號],Get_Stock_Info,COLUMN(Get_Stock_Info[[#標題],[Total Sam points]]), FALSE)
            let 主板市值低於1o5億 //=IF(LEFT([@股票編號],2)="08",0, IF([@[市值(億)]]<1.5,1,0))
            let 創業板市值低於5000萬 //=IF(LEFT([@股票編號],2)="08",IF([@[市值(億)]]<0.5,1,0), 0)
            let 上市未夠5年 //=IF([@上市日子]="-","-",IF([@上市日子]-$C$1<-(5*365+1),"-","Yes"))
            let VDCACurrentRatio; //=IFERROR([@VDCA]/[@流動負債],0)
            let 現價smaller打劫價; //=IF(ISERROR([@[現價 (Excel)]]),"", IF([@[現價 (Excel)]]<[@打劫價],1,0))
            let 現價smallerNetCash //=IF(ISERROR([@[Net Cash]]),"", IF(ISERROR([@[現價 (Excel)]]),"", IF([@[現價 (Excel)]]<[@[Net Cash]],2,0)))
            let DCA_fcf_cl_0; //=IF(([@流動資產]-(0.5*[@存貨])-(0.3*[@應收賬])+[@FCF]-[@非流動負債])>0,1,0)
            let COERanking; // =IF([@COE]<0,-1,IF([@COE]>0,IF([@COE]<10%,1,IF([@COE]>10%,IF([@COE]<20%,2,0)))))
            let 現價回報率BiggerCoe_1; //=IF([@現價回報率]>[@COE],1,0)
            let COEBigger0; //=IF([@COE]>=$BQ$2,1,0)
            let NCA_3XFCFBiggerNetCash; //=IF([@NCA]+3*[@每股自由現金流(港元)]>[@[Net Cash]],1,0)
            let NCA_3XFCFBigger打劫價; //=IF([@NCA]+3*[@[每股自由現金流(港元)]]>[@打劫價],1,0)
            let VDCACurrentRatioBigger1; //=IF([@[VDCA Current Ratio (1.5-3)]]>1,1,0)
            let 派息Bigger0; //=IF([@[股息%]]>0,1,0)
            let 市值億smaller3 //=IF([@[市值(億)]]<=$BY$2,1,0)
            let TotalRank;

            let tempArray = [];




            if (moment(v.股息除淨日, 'YYYY/MM/DD').isValid() && moment(v.股息除淨日, 'YYYY/MM/DD').isAfter(today)) {
                打劫價調整 = v.打劫價
            } else {
                打劫價調整 = v.打劫價 - 0 //0 <--股息2????
                // console.log("股息2????")
            }

            netCash = (v.現金 - v.TL) / v["股數(股)"] * v.單位;
            距離打劫價 = Math.abs((v.打劫價 - v.現價) / v.現價)
            市值_億 = v["市值(港元)"] / 100000000
            股息Present = 0 / v.現價 //0 <--股息2????

            if (v.股票編號.slice(0, 2) !== "08" && (v["市值(港元)"] / 100000000) < 1.5) {
                主板市值低於1o5億 = 1;
            } else {
                主板市值低於1o5億 = 0;
            }

            if (v.股票編號.slice(0, 2) === "08" && (v["市值(港元)"] / 100000000) < 0.5) {
                創業板市值低於5000萬 = 1;
            } else {
                創業板市值低於5000萬 = 0;
            }

            if (v.上市日期 === '-') {
                上市未夠5年 = '-';
            } else {
                const yearsDiff = today.diff(moment(v.上市日期,'YYYY/MM/DD'), 'years');
                if (yearsDiff < 5) {
                    上市未夠5年 = 'Yes';
                } else {
                    上市未夠5年 = '-';
                }
            }

            VDCACurrentRatio = v.VDCA / v.流動負債;
            //-----------------------//
            現價smaller打劫價 = v.現價 < v.打劫價 ? 1 : "-";
            tempArray.push(現價smaller打劫價)
            現價smallerNetCash = v.現價 < netCash ? 2 : "-";
            tempArray.push(現價smallerNetCash)
            DCA_fcf_cl_0 = (v.流動資產 - (0.5 * v.存貨) - (0.3 * v.應收賬) + v.FCF - v.非流動負債) > 0 ? 1 : "-";
            tempArray.push(DCA_fcf_cl_0)
            //COERanking//////////
            if (v.COE <= 0) {
                COERanking = -1;
            } else if (v.COE > 0 && v.COE < 0.1) { // COE < 10%
                COERanking = 1;
            } else if (v.COE >= 0.1 && v.COE < 0.2) { // 10% <= COE < 20%
                COERanking = 2;
            } else if (v.COE >= 0.2) { // COE >= 20%
                COERanking = 3;
            }
            tempArray.push(COERanking)
            現價回報率BiggerCoe_1 = v.現價回報率 > v.COE ? 1 : "-";
            tempArray.push(現價回報率BiggerCoe_1)
            COEBigger0 = v.COE >= 0 ? 1 : "-";
            tempArray.push(COEBigger0)
            NCA_3XFCFBiggerNetCash = (v.NCA + 3 * v["每股自由現金流(港元)"]) > netCash ? 1 : "-";
            tempArray.push(NCA_3XFCFBiggerNetCash)
            NCA_3XFCFBigger打劫價 = (v.NCA + 3 * v["每股自由現金流(港元)"]) > v.打劫價 ? 1 : "-";
            tempArray.push(NCA_3XFCFBigger打劫價)
            VDCACurrentRatioBigger1 = VDCACurrentRatio > 1 ? 1 : "-";
            tempArray.push(VDCACurrentRatioBigger1)
            派息Bigger0 = 股息Present > 0 ? 1 : "-";
            tempArray.push(派息Bigger0)
            市值億smaller3 = (v["市值(港元)"] / 100000000) <= 3 ? 1 : "-"
            tempArray.push(市值億smaller3)
            TotalRank = totalRankSum(tempArray)

            return {
                ...v,
                打劫價調整
                , netCash
                , 距離打劫價
                , 股息Present
                // , PSScore
                // , SamPoints
                , 主板市值低於1o5億
                , 創業板市值低於5000萬
                , 上市未夠5年
                , VDCACurrentRatio
                , 現價smaller打劫價
                , 現價smallerNetCash
                , DCA_fcf_cl_0
                , COERanking
                , 現價回報率BiggerCoe_1
                , COEBigger0
                , NCA_3XFCFBiggerNetCash
                , NCA_3XFCFBigger打劫價
                , VDCACurrentRatioBigger1
                , 派息Bigger0
                , 市值億smaller3
                , TotalRank
                , 市值_億
            }

        })
        return calVDCAStock

    } else {
        throw new Error("Stock data not provided");
    }
}

function totalRankSum(tempArray) {
    let ans = 0
    for (let a of tempArray) {
        if (!isNaN(a)) {
            ans += a
        }
    }
    return ans
}