import React, { useContext, useEffect, useRef, useState } from "react";
import ThemeContext from "../components/ThemeContext.js";
import {
  fetchStockData,
  fetchDataLatestTime,
} from "../utils/fetchStockDataAndTime.js";
import { useSelector } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import calculateVDCAStock from "../utils/calculateVDCAStock.js";
import * as f from "../utils/formatVDCAStock.js";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { MultiSelect } from "primereact/multiselect";
import "./VDCAPage.css";
import TitleBar from "../components/TitleBar.js";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import secureLocalStorage from "react-secure-storage";
import numeral from "numeral";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";

const VDCAPage = () => {
  const { theme } = useContext(ThemeContext);
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const [VDCAStock, setVDCAStock] = useState(null);
  const [industries, setIndustries] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [multiSortMeta, setMultiSortMeta] = useState([]);
  const [firstState, setFirstState] = useState(0);
  const [sortFieldState, setSortFieldState] = useState("");
  const [sortOrderState, setSortOrderState] = useState(1);
  const [filters, setFilters] = useState({
    股票編號: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    公司名稱: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    行業: { value: null, matchMode: FilterMatchMode.IN },
    現價: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.LESS_THAN }],
    },
    打劫價: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    打劫價調整: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    netCash: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    NCA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    現價回報率_multiply100: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    筍價: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    COE_multiply100: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    距離打劫價_multiply100: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    FCF: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    市值_億: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    _52週高: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    _52週低: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "股數(股)": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    報告貨幣匯率: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    每手股數: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "每手價格(港元)": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    OCF: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    CE: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    E: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    VDCA: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    TL: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    單位: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    流動資產: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    存貨: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    應收賬: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    現金: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    非流動負債: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    流動負債: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "市值(港元)": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "Net Cash價": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    少數股東權益: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    "每股自由現金流(港元)": {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    主板市值低於1o5億: { value: null, matchMode: FilterMatchMode.EQUALS },
    創業板市值低於5000萬: { value: null, matchMode: FilterMatchMode.EQUALS },
    上限價: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    市場價: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    VDCACurrentRatio: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
    現價smaller打劫價: { value: null, matchMode: FilterMatchMode.EQUALS },
    現價smallerNetCash: { value: null, matchMode: FilterMatchMode.EQUALS },
    DCA_fcf_cl_0: { value: null, matchMode: FilterMatchMode.EQUALS },
    COERanking: { value: null, matchMode: FilterMatchMode.EQUALS },
    現價回報率BiggerCoe_1: { value: null, matchMode: FilterMatchMode.EQUALS },
    COEBigger0: { value: null, matchMode: FilterMatchMode.EQUALS },
    NCA_3XFCFBiggerNetCash: { value: null, matchMode: FilterMatchMode.EQUALS },
    NCA_3XFCFBigger打劫價: { value: null, matchMode: FilterMatchMode.EQUALS },
    VDCACurrentRatioBigger1: { value: null, matchMode: FilterMatchMode.EQUALS },
    派息Bigger0: { value: null, matchMode: FilterMatchMode.EQUALS },
    市值億smaller3: { value: null, matchMode: FilterMatchMode.EQUALS },
    TotalRank: {
      operator: FilterOperator.AND,
      constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
    },
  });
  const [totalRecords, setTotalRecords] = useState(0); // Total records count

  const orgVDCAStock = useRef(null);
  let orgLatestTime = useRef(null);

  function filterVDCA(data) {
    if (data) {
      return data.filter((d) => d["現價"] < d["打劫價"]);
    }
  }

  //score_0_1 Filter////////
  const scoreFilterFilterItemTemplate = (option) => {
    return <div>{option}</div>;
  };
  const [scoreFilter01] = useState(["-", 1]);

  const scoreFilter_0_1RowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={scoreFilter01}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={scoreFilterFilterItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };
  //score_0_1 Filter////////

  //score_0_2 Filter////////

  const [scoreFilter02] = useState([0, 2]);

  const scoreFilter_0_2RowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={scoreFilter02}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={scoreFilterFilterItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };
  //score_0_2 Filter////////

  //score_0_2 Filter////////

  const [scoreFilter03] = useState([-1, 1, 2, 3]);

  const scoreFilter_0_3RowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={scoreFilter03}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={scoreFilterFilterItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };
  //score_0_2 Filter////////

  //Industries Filter/////
  const getIndustries = (formatResult) => {
    if (formatResult) {
      return setIndustries([...new Set(formatResult.map((i) => i.行業))]);
    }
  };

  const industriesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option}</span>
      </div>
    );
  };

  const industryRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={industries}
        itemTemplate={industriesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="任何"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };
  //Industries Filter/////

  const clearFilter = () => {
    setFilters({
      股票編號: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      公司名稱: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      行業: { value: null, matchMode: FilterMatchMode.IN },
      現價: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.LESS_THAN }],
      },
      打劫價: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      打劫價調整: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      netCash: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      NCA: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      現價回報率_multiply100: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      筍價: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      COE_multiply100: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      距離打劫價_multiply100: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      FCF: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      市值_億: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      _52週高: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      _52週低: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      "股數(股)": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      報告貨幣匯率: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      每手股數: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      "每手價格(港元)": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      OCF: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      CE: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      E: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      VDCA: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      TL: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      單位: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      流動資產: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      存貨: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      應收賬: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      現金: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      非流動負債: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      流動負債: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      "市值(港元)": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      "Net Cash價": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      少數股東權益: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      "每股自由現金流(港元)": {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      主板市值低於1o5億: { value: null, matchMode: FilterMatchMode.EQUALS },
      創業板市值低於5000萬: { value: null, matchMode: FilterMatchMode.EQUALS },
      上限價: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      市場價: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      VDCACurrentRatio: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
      現價smaller打劫價: { value: null, matchMode: FilterMatchMode.EQUALS },
      現價smallerNetCash: { value: null, matchMode: FilterMatchMode.EQUALS },
      DCA_fcf_cl_0: { value: null, matchMode: FilterMatchMode.EQUALS },
      COERanking: { value: null, matchMode: FilterMatchMode.EQUALS },
      現價回報率BiggerCoe_1: { value: null, matchMode: FilterMatchMode.EQUALS },
      COEBigger0: { value: null, matchMode: FilterMatchMode.EQUALS },
      NCA_3XFCFBiggerNetCash: {
        value: null,
        matchMode: FilterMatchMode.EQUALS,
      },
      NCA_3XFCFBigger打劫價: { value: null, matchMode: FilterMatchMode.EQUALS },
      VDCACurrentRatioBigger1: {
        value: null,
        matchMode: FilterMatchMode.EQUALS,
      },
      派息Bigger0: { value: null, matchMode: FilterMatchMode.EQUALS },
      市值億smaller3: { value: null, matchMode: FilterMatchMode.EQUALS },
      TotalRank: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
      },
    });
    clearSorting();
    secureLocalStorage.removeItem("datatable_sort_vdca");
    secureLocalStorage.removeItem("datatable_filters_vdca");
  };

  const customSortFunction = (event) => {
    const { multiSortMeta, data } = event;
    // Sort the data based on multiSortMeta
    let sortedDataCopy = [...data];

    sortedDataCopy.sort((a, b) => {
      for (let i = 0; i < multiSortMeta.length; i++) {
        const sortMeta = multiSortMeta[i];
        const { field, order } = sortMeta;

        // const convertedA = numeral(a[field].replace("%", "")).value();
        // const convertedB = numeral(b[field].replace("%", "")).value();

        // Special case: comparing "-" and "1"
        if (parseInt(a[field]) > 0 && b[field] === "-") return order;
        if (parseInt(b[field]) > 0 && a[field] === "-") return -order;

        // if (convertedA > convertedB) return order;
        // if (convertedA < convertedB) return -order;
      }
      return 0;
    });

    return sortedDataCopy;
  };

  const customOnSort = (event) => {
    const { multiSortMeta, sortOrder, sortField } = event;
    setMultiSortMeta(multiSortMeta);
    setSortFieldState(sortField);
    setSortOrderState(sortOrder);
    setFirstState(0);
    secureLocalStorage.setItem("datatable_sort_vdca", JSON.stringify(event));
  };

  const clearSorting = () => {
    setVDCAStock(orgVDCAStock.current);
    setMultiSortMeta([]);
    setSortFieldState("");
    setSortOrderState(1);
  };

  function refreshingData() {
    setLoading(true);
    // clearFilter();
    setRefreshData(true);
  }

  const onFilterChange = (field, value) => {
    setFilters(value);
    secureLocalStorage.setItem("datatable_filters_vdca", JSON.stringify(value));
  };

  const onFilter = (event) => {
    event && setTotalRecords(event.length ? event.length : 0);
    // setTotalRecords(event.length ? event.length : -1);
  };

  const percentFilterTemplate = (options) => {
    return (
      <InputNumber
        value={options.value}
        onChange={(e) => options.filterCallback(e.value, options.index)}
        inputId="percent"
        suffix="%"
        minFractionDigits={0}
        maxFractionDigits={2}
      />
    );
  };

  useEffect(() => {
    async function fetchStockDataAndTime() {
      const data = await fetchStockData(
        getAccessTokenSilently,
        loginWithRedirect
      );
      const filterVDCAResult = filterVDCA(data);
      const calResult = calculateVDCAStock(filterVDCAResult);
      const formatResult = await f.formatVDCAStock(calResult);
      orgVDCAStock.current = formatResult;
      getIndustries(formatResult);
      setVDCAStock(formatResult);
      setTotalRecords(formatResult.length);
      if (orgVDCAStock.current) {
        const dataLatestTime = await fetchDataLatestTime(
          getAccessTokenSilently,
          loginWithRedirect
        );
        orgLatestTime.current = dataLatestTime;
      }
      setLoading(false);
      setRefreshData(false);
    }
    if (isAuthenticated) {
      fetchStockDataAndTime();
      const storedFilters = secureLocalStorage.getItem(
        "datatable_filters_vdca"
      );
      if (storedFilters) {
        setFilters(JSON.parse(storedFilters));
      }
      const storedSort = secureLocalStorage.getItem("datatable_sort_vdca");
      if (storedSort) {
        const { multiSortMeta, sortOrder, sortField } = JSON.parse(storedSort);
        setMultiSortMeta(multiSortMeta);
        setSortFieldState(sortField);
        setSortOrderState(sortOrder);
        setFirstState(0);
      }
    }
  }, [refreshData]);

  return (
    <div className={`App theme-${theme} height90`}>
      <>
        <TitleBar
          title="VDCA"
          loading={loading}
          totalRecords={totalRecords}
          orgLatestTime={orgLatestTime.current}
          refreshingData={refreshingData}
          clearFilter={clearFilter}
        ></TitleBar>

        <div>
          <DataTable
            value={VDCAStock}
            scrollable
            scrollHeight="77vh"
            resizableColumns
            showGridlines
            stripedRows
            paginator
            rows={20}
            rowsPerPageOptions={[5, 10, 25, 50]}
            filters={filters}
            filterDisplay="menu"
            loading={loading}
            onValueChange={onFilter}
            onFilter={(e) => onFilterChange(e.field, e.filters)}
            removableSort
            sortMode="multiple"
            multiSortMeta={multiSortMeta}
            sortField={sortFieldState}
            sortOrder={sortOrderState}
            first={firstState}
            onSort={customOnSort}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            emptyMessage="No stock data found."
          >
            <Column
              field="股票編號"
              header="股票編號"
              footer="股票編號"
              frozen
              filter
              showFilterMenu={true}
              sortable
              filterDelay={1000}
              filterPlaceholder="股票編號"
            ></Column>
            <Column
              field="公司名稱"
              header="公司名稱"
              footer="公司名稱"
              frozen
              filter
              sortable
              showFilterMenu={true}
              filterDelay={1000}
              filterPlaceholder="公司名稱"
            ></Column>
            <Column
              field="行業"
              header="行業"
              footer="行業"
              showFilterMenu={true}
              filter
              sortable
              showFilterMatchModes={false}
              filterElement={industryRowFilterTemplate}
              filterMenuStyle={{ width: "16rem" }}
            ></Column>
            <Column
              field="現價"
              header="現價"
              footer="現價"
              sortable
              dataType="numeric"
              filter
              body={f.現價BodyTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="打劫價"
              header="打劫價"
              footer="打劫價"
              sortable
              dataType="numeric"
              filter
              body={f.打劫價BodyTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="打劫價調整"
              header="打劫價調整 （股息減值）"
              footer="打劫價調整 （股息減值）"
              sortable
              dataType="numeric"
              filter
              body={f.打劫價調整BodyTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="netCash"
              header="Net Cash"
              footer="Net Cash"
              dataType="numeric"
              filter
              sortable
              body={f.netCashBodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>

            <Column
              field="NCA"
              header="NCA"
              footer="NCA"
              sortable
              filter
              dataType="numeric"
              body={f.ncaBodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="現價回報率_multiply100"
              header="現價回報率"
              footer="現價回報率"
              sortable
              filter
              dataType="numeric"
              body={f.現價回報率BodyTemplate}
              filterElement={percentFilterTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="筍價"
              header="筍價"
              footer="筍價"
              sortable
              filter
              dataType="numeric"
              body={f.筍價BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="COE_multiply100"
              header="COE"
              footer="COE"
              sortable
              filter
              dataType="numeric"
              body={f.coeBodyTemplate}
              filterElement={percentFilterTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="距離打劫價_multiply100"
              header="距離打劫價"
              footer="距離打劫價"
              sortable
              filter
              dataType="numeric"
              body={f.距離打劫價BodyTemplate}
              filterElement={percentFilterTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="FCF"
              header="FCF"
              footer="FCF"
              sortable
              filter
              dataType="numeric"
              body={f.fcfBodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="市值_億"
              header="市值(億)"
              footer="市值(億)"
              sortable
              filter
              dataType="numeric"
              body={f.市值_億BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="_52週高"
              header="52週高"
              footer="52週高"
              sortable
              filter
              dataType="numeric"
              body={f._52週高BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="_52週低"
              header="52週低"
              footer="52週低"
              sortable
              filter
              dataType="numeric"
              body={f._52週低BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="股數(股)"
              header="股數(股)"
              footer="股數(股)"
              sortable
              filter
              dataType="numeric"
              body={f.股數_股BodyTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="報告貨幣"
              header="報告貨幣"
              footer="報告貨幣"
              sortable
              sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="報告貨幣匯率"
              header="報告貨幣匯率"
              footer="報告貨幣匯率"
              sortable
              filter
              dataType="numeric"
              body={f.報告貨幣匯率BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="每手股數"
              header="每手股數"
              footer="每手股數"
              sortable
              filter
              dataType="numeric"
              body={f.每手股數BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="每手價格(港元)"
              header="每手價格(港元)"
              footer="每手價格(港元)"
              sortable
              filter
              dataType="numeric"
              body={f.每手價格_港元BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="最近財報日期(BS & PL)"
              header="最近財報日期(BS & PL)"
              footer="最近財報日期(BS & PL)"
              sortable
              className="text-align-right"
            ></Column>
            <Column
              field="最近財報日期(CF)"
              header="最近財報日期(CF)"
              footer="最近財報日期(CF)"
              sortable
              className="text-align-right"
            ></Column>
            <Column
              field="年報類別(CF)"
              header="年報類別(CF)"
              footer="年報類別(CF)"
              sortable
              className="text-align-right"
            ></Column>
            <Column
              field="BS&PL財報日期與CF不同"
              header="BS&PL財報日期與CF不同"
              footer="BS&PL財報日期與CF不同"
              sortable
              className="text-align-right"
            ></Column>
            <Column
              field="OCF"
              header="OCF"
              footer="OCF"
              sortable
              filter
              dataType="numeric"
              body={f.ocfBodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="CE"
              header="CE"
              footer="CE"
              sortable
              filter
              dataType="numeric"
              body={f.ceBodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="E"
              header="E"
              footer="E"
              sortable
              filter
              dataType="numeric"
              body={f.eBodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="VDCA"
              header="VDCA"
              footer="VDCA"
              sortable
              filter
              dataType="numeric"
              body={f.vdcaBodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="TL"
              header="TL"
              footer="TL"
              sortable
              filter
              dataType="numeric"
              body={f.tlBodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="單位"
              header="單位"
              footer="單位"
              sortable
              filter
              dataType="numeric"
              body={f.單位BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="流動資產"
              header="流動資產"
              footer="流動資產"
              sortable
              filter
              dataType="numeric"
              body={f.流動資產BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="存貨"
              header="存貨"
              footer="存貨"
              sortable
              filter
              dataType="numeric"
              body={f.存貨BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="應收賬"
              header="應收賬"
              footer="應收賬"
              sortable
              filter
              dataType="numeric"
              body={f.應收賬BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="現金"
              header="現金"
              footer="現金"
              sortable
              filter
              dataType="numeric"
              body={f.現金BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="非流動負債"
              header="非流動負債"
              footer="非流動負債"
              sortable
              filter
              dataType="numeric"
              body={f.非流動負債BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="流動負債"
              header="流動負債"
              footer="流動負債"
              sortable
              filter
              dataType="numeric"
              body={f.流動負債BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="市值(港元)"
              header="市值(港元)"
              footer="市值(港元)"
              sortable
              filter
              dataType="numeric"
              body={f.市值_港元BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="Net Cash價"
              header="Net Cash價"
              footer="Net Cash價"
              sortable
              filter
              dataType="numeric"
              body={f.NetCash價BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="少數股東權益"
              header="少數股東權益"
              footer="少數股東權益"
              sortable
              filter
              dataType="numeric"
              body={f.少數股東權益BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="每股自由現金流(港元)"
              header="每股自由現金流(港元)"
              footer="每股自由現金流(港元)"
              sortable
              filter
              dataType="numeric"
              body={f.每股自由現金流_港元BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="股息"
              header="股息"
              footer="股息"
              sortable
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="股息除淨日"
              header="股息除淨日"
              footer="股息除淨日"
              sortable
              className="text-align-right"
            ></Column>
            <Column
              field="主板市值低於1o5億"
              header="如果是主板（非 8 字頭股票，市值低於 1.5億 有 1 分）"
              footer="如果是主板（非 8 字頭股票，市值低於 1.5億 有 1 分）"
              sortable
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="創業板市值低於5000萬"
              header="如果是創業板（ 8 字頭股票，市值低於 5000萬 有 1 分）"
              footer="如果是創業板（ 8 字頭股票，市值低於 5000萬 有 1 分）"
              sortable
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="上市日期"
              header="上市日期"
              footer="上市日期"
              sortable
              className="text-align-right"
            ></Column>
            <Column
              field="上市未夠5年"
              header="上市未夠5年"
              footer="上市未夠5年"
              sortable
              className="text-align-right"
            ></Column>
            <Column
              field="上限價"
              header="上限價"
              footer="上限價"
              sortable
              filter
              dataType="numeric"
              body={f.上限價BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="市場價"
              header="市場價"
              footer="市場價"
              sortable
              filter
              dataType="numeric"
              body={f.市場價BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="VDCACurrentRatio"
              header="VDCA Current Ratio (1.5-3)"
              footer="VDCA Current Ratio (1.5-3)"
              sortable
              filter
              dataType="numeric"
              body={f.VDCACurrentRatioBodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="現價smaller打劫價"
              header="現價<打劫價"
              footer="現價<打劫價"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="現價smallerNetCash"
              header="現價< Net Cash"
              footer="現價< Net Cash"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_2RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="DCA_fcf_cl_0"
              header="DCA+fcf-cl>0"
              footer="DCA+fcf-cl>0"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="COERanking"
              header="COE Ranking"
              footer="COE Ranking"
              sortable
              filter
              filterElement={scoreFilter_0_3RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="現價回報率BiggerCoe_1"
              header="現價回報率>coe*1 "
              footer="現價回報率>coe*1 "
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="COEBigger0"
              header="coe >=0% "
              footer="coe >=0% "
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="NCA_3XFCFBiggerNetCash"
              header="NCA+3XFCF > Net Cash"
              footer="NCA+3XFCF > Net Cash"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="NCA_3XFCFBigger打劫價"
              header="NCA+3XFCF > 打劫價"
              footer="NCA+3XFCF > 打劫價"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="VDCACurrentRatioBigger1"
              header="VDCA Current Ratio >1"
              footer="VDCA Current Ratio >1"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="派息Bigger0"
              header="派息>0"
              footer="派息>0"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="市值億smaller3"
              header="市值(億) <= 3 "
              footer="市值(億) <= 3 "
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="TotalRank"
              header="Total Rank"
              footer="Total Rank"
              sortable
              filter
              dataType="numeric"
              body={f.TotalRankBodyTemplate}
              className="text-align-right"
            ></Column>
          </DataTable>
        </div>
      </>
    </div>
  );
};

export default VDCAPage;
