import ThemeContext from "./ThemeContext.js";
import { useContext } from "react";
import { Link } from "react-router-dom";
import iconImage from "../assets/icon.jpg";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar } from "primereact/avatar";
import { Button } from "primereact/button";
import ThemeToggle from "./ThemeToggle.js";
import "./NavBar.css";
import { useSelector } from "react-redux";
import { Menubar } from "primereact/menubar";
import { useLocation } from "react-router-dom";

export default function NavBar() {
  const { theme } = useContext(ThemeContext);
  // const { user, logout, isAuthenticated } = useAuth0();
  const { logout } = useAuth0();

  const { isAuthenticated, user } = useSelector((state) => state.auth);

  const currentPathName = useLocation().pathname;

  return (
    isAuthenticated && (
      <div className={`App theme-${theme} nav-bar`}>
        <Menubar
          model={[
            {
              label: "Summary",
              icon: "pi pi-home",
              url: "/",
              className: currentPathName === "/" ? "menuitem-path-current" : "",
            },

            {
              label: "VDCA",
              icon: "pi pi-tags",
              url: "/vdca",
              className:
                currentPathName === "/vdca" ? "menuitem-path-current" : "",

              // items: [
              //   {
              //     label: 'Styled',
              //     url: '/theming'
              //   },
              //   {
              //     label: 'Unstyled',
              //     url: '/unstyled'
              //   }
              // ]
            },

            // , {
            //   label: 'Profile',
            //   icon: 'pi pi-star',
            //   url: '/profile'
            // }
          ]}
          start={
            <Link to={"/"} className="text-decoration">
              <h2 className="text-color stock-tracker">
                <Avatar image={iconImage} />
                &nbsp;StockTracker
              </h2>
            </Link>
          }
          end={
            <div style={{ display: "flex", alignItems: "center" }}>
              <Avatar image={user.picture} shape="circle" />
              <span className="text-color">
                &nbsp;Welcome back {user.name} !
              </span>
              &nbsp;
              <ThemeToggle />
              &nbsp;
              <Button
                type="button"
                icon="pi pi-sign-out"
                rounded
                text
                severity="secondary"
                size="small"
                onClick={() =>
                  logout({
                    logoutParams: {
                      // returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL,
                      returnTo: window.location.origin,
                    },
                  })
                }
              ></Button>
            </div>
          }
        />
      </div>

      // <div className={`App theme-${theme} nav-bar`}>
      //   <Link to={"/"} className="text-decoration">
      //     <h2 className="text-color padding-left " >
      //       <Avatar image={iconImage} />
      //       &nbsp;StockTracker
      //     </h2>
      //   </Link>

      //   <div style={{ display: "flex", alignItems: "center" }}>
      //     <Avatar image={user.picture} shape="circle" />
      //     <span className="text-color">&nbsp;Welcome back {user.name} !</span>
      //     &nbsp;
      //     <ThemeToggle />
      //     &nbsp;
      //     <Button
      //       type="button"
      //       icon="pi pi-sign-out"
      //       rounded
      //       text
      //       severity="secondary"
      //       size="small"
      //       onClick={() =>
      //         logout({
      //           logoutParams: {
      //             returnTo: process.env.REACT_APP_AUTH0_CALLBACK_URL,
      //           },
      //         })
      //       }
      //     ></Button>
      //   </div>
      // </div>
    )
  );
}
