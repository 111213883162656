// var numeral = require('numeral');
import { percent, currencyRate, withoutDecimal, hasDecimalFormat } from './formatCommonFunction.js'

export  async function formatStockInfo(calResult) {
    if (calResult) {
        const formatResult = await calResult.map(c => {
            // let 現價;
            let netCash;
            // let 打劫價;
            // let NCA;
            // let 上限價;
            // let 市場價;
            // let 筍價;
            let 現價回報率_multiply100; //%
            let COE_multiply100; //%
            // let FCF;
            // let 每股自由現金流_港元;
            // let 市值_億;
            let _52週高;
            let _52週低;
            // let 股數_股;
            // let 報告貨幣匯率;
            // let 總負債;
            // let 每手股數;
            // let 每手價格_港元;
            // let 退回稅項;
            // let 營業總額;
            // let Turnover_T_3;
            // let Turnover_T_2;
            // let Turnover_T_1;
            // let Turnover_T_0;
            // let 銷售成本;
            // let 股東應佔溢利;
            // let NetProfit_T_3;
            // let NetProfit_T_2;
            // let NetProfit_T_1;
            // let NetProfit_T_0;
            // let OCF_T_1;
            // let OCF;
            let OCF_OCF_T_1_multiply100; //%
            // let CE;
            // let E;
            // let VDCA;
            // let TL;
            // let 單位;
            // let 存貨;
            // let 應收賬;
            // let 現金;
            // let 流動資產;
            // let 總資產;
            // let 非流動負債;
            // let 流動負債;
            let VDCACurrentRatio;
            let CE_FCF_multiply100; //%
            // let 少數股東權益;
            // let 市值_港元;
            let NetCash價_multiply100; //%
            let 市盈率;
            let 股息率_multiply100; //%
            let 派息比率_multiply100; //%
            // let 每股派息;
            // let COERanking;
            let interestBiggerabove_multiply100; //%
            let COESpark;
            let FCFSpark;

            // 現價 = currencyRate(c.現價)
            netCash = +((c.netCash).toFixed(2))
            // 打劫價 = mixFormat(c.打劫價)
            // NCA = mixFormat(c.NCA)
            // 上限價 = mixFormat(c.上限價)
            // 市場價 = mixFormat(c.市場價)
            // 筍價 = mixFormat(c.筍價)
            現價回報率_multiply100 = +((c.現價回報率 * 100).toFixed(2));
            COE_multiply100 = +((c.COE * 100).toFixed(2))
            // FCF = mixFormat(c.FCF)
            // 每股自由現金流_港元 = mixFormat(c["每股自由現金流(港元)"])
            // 市值_億 = mixFormat(c["市值_億"])
            _52週高 = c["52週高"] > 0 ? c["52週高"] : ""
            _52週低 = c["52週低"] > 0 ? c["52週低"] : ""
            // 股數_股 = mixFormat(c["股數(股)"])
            // 報告貨幣匯率 = currencyRate(c.報告貨幣匯率)
            // 總負債 = mixFormat(c.總負債)
            // 每手股數 = mixFormat(c.每手股數);
            // 每手價格_港元 = mixFormat(c["每手價格(港元)"]);
            // 退回稅項 = mixFormat(c.退回稅項);
            // 營業總額 = mixFormat(c.營業總額);
            // Turnover_T_3 = mixFormat(c["Turnover-T-3"]);
            // Turnover_T_2 = mixFormat(c["Turnover-T-2"]);
            // Turnover_T_1 = mixFormat(c["Turnover-T-1"]);
            // Turnover_T_0 = mixFormat(c["Turnover-T-0"]);
            // 銷售成本 = mixFormat(c.銷售成本);
            // 股東應佔溢利 = mixFormat(c.股東應佔溢利);
            // NetProfit_T_3 = mixFormat(c["NetProfit-T-3"]);
            // NetProfit_T_2 = mixFormat(c["NetProfit-T-2"]);
            // NetProfit_T_1 = mixFormat(c["NetProfit-T-1"]);
            // NetProfit_T_0 = mixFormat(c["NetProfit-T-0"]);
            // OCF_T_1 = mixFormat(c["OCF-T-1"]);
            // OCF = mixFormat(c.OCF);
            // OCF_OCF_T_1 = percent(c["OCF/OCF-T-1"]);
            OCF_OCF_T_1_multiply100 = +((c["OCF/OCF-T-1"] * 100).toFixed(2))

            // CE = mixFormat(c.CE);
            // E = mixFormat(c.E);
            // VDCA = mixFormat(c.VDCA);
            // TL = mixFormat(c.TL);
            // 單位 = mixFormat(c.單位);
            // 存貨 = mixFormat(c.存貨);
            // 應收賬 = mixFormat(c.應收賬);
            // 現金 = mixFormat(c.現金)
            // 流動資產 = mixFormat(c.流動資產);
            // 總資產 = mixFormat(c.總資產);
            // 非流動負債 = mixFormat(c.非流動負債);
            // 流動負債 = mixFormat(c.流動負債);
            VDCACurrentRatio = +c.VDCACurrentRatio.toFixed(0);
            // CE_FCF = percent(c.CE_FCF);
            CE_FCF_multiply100 = +((c.CE_FCF * 100).toFixed(2))

            // 少數股東權益 = mixFormat(c.少數股東權益);
            // 市值_港元 = withoutDecimal(c["市值(港元)"]);
            // NetCash價 = percent(c["Net Cash價"]);
            NetCash價_multiply100 = +((c["Net Cash價"] * 100).toFixed(2))

            市盈率 = c.市盈率 ? c.市盈率 : '-';
            // 股息率 = percent(c.收益率);
            股息率_multiply100 = +((c.股息率 * 100).toFixed(2))
            派息比率_multiply100 = +((c.派息比率 * 100).toFixed(2))

            // 派息比率 = percent(c.派息比率);
            // 每股派息 = mixFormat(c.每股派息);
            // COERanking = mixFormat(c.COERanking);
            // interestBiggerabove = percent(c.interestBiggerabove);
            interestBiggerabove_multiply100 = +((c.interestBiggerabove * 100).toFixed(2))


            COESpark = [{
                name: 'COE',
                data: [c["COE_Y-3"] ? c["COE_Y-3"] : 0, c["COE_Y-2"] ? c["COE_Y-2"] : 0, c["COE_Y-1"] ? c["COE_Y-1"] : 0, c["COE"] ? c["COE"] : 0]
            }]

            FCFSpark = [{
                name: 'FCF',
                data: [c["FCF_Y-4"] ? c["FCF_Y-4"] : 0, c["FCF_Y-3"] ? c["FCF_Y-3"] : 0, c["FCF_Y-2"] ? c["FCF_Y-2"] : 0, c["FCF_Y-1"] ? c["FCF_Y-1"] : 0, c["FCF"] ? c["FCF"] : 0]
            }]

            return {
                ...c
                // , 現價
                , netCash
                // , 打劫價
                // , NCA
                // , 上限價
                // , 市場價
                // , 筍價
                , 現價回報率_multiply100 //%
                , COE_multiply100 //%
                // , FCF
                // , 每股自由現金流_港元
                // , 市值_億
                , _52週高
                , _52週低
                // , 股數_股
                // , 報告貨幣匯率
                // , 總負債
                // , 每手股數
                // , 每手價格_港元
                // , 退回稅項
                // , 營業總額
                // , Turnover_T_3
                // , Turnover_T_2
                // , Turnover_T_1
                // , Turnover_T_0
                // , 銷售成本
                // , 股東應佔溢利
                // , NetProfit_T_3
                // , NetProfit_T_2
                // , NetProfit_T_1
                // , NetProfit_T_0
                // , OCF_T_1
                // , OCF
                , OCF_OCF_T_1_multiply100
                // , CE
                // , E
                // , VDCA
                // , TL
                // , 單位
                // , 存貨
                // , 應收賬
                // , 現金
                // , 流動資產
                // , 總資產
                // , 非流動負債
                // , 流動負債
                , VDCACurrentRatio
                , CE_FCF_multiply100 //%
                // , 少數股東權益
                // , 市值_港元
                , NetCash價_multiply100 //%
                , 市盈率
                , 股息率_multiply100 //%
                , 派息比率_multiply100 //%
                // , 每股派息
                // , COERanking
                , interestBiggerabove_multiply100 //%
                , COESpark
                , FCFSpark
            }
        })
        return formatResult;

    } else {
        throw new Error("calculated stock data not provided");
    }

}


export const 現價BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.現價);
};

export const netCashBodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.netCash);
};

export const 打劫價BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.打劫價);
};
export const ncaBodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.NCA);
};

export const 上限價BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.上限價);
};

export const 市場價BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.市場價);
};

export const 筍價BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.筍價);
};

export const 現價回報率BodyTemplate = (rowData) => {
    return percent(rowData.現價回報率);
};

export const coeBodyTemplate = (rowData) => {
    return percent(rowData.COE);
};

export const fcfBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.FCF);
};

export const 每股自由現金流_港元BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData["每股自由現金流(港元)"]);
};

export const 市值_億BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.市值_億);
};

export const _52週高BodyTemplate = (rowData) => {
    return currencyRate(rowData._52週高);
};

export const _52週低BodyTemplate = (rowData) => {
    return currencyRate(rowData._52週低);
};

export const 股數_股BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["股數(股)"]);
};

export const 報告貨幣匯率BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.報告貨幣匯率);
};

export const 總負債BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.總負債);
};

export const 每手股數BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.每手股數);
};

export const 每手價格_港元BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["每手價格(港元)"]);
};

export const 退回稅項BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.退回稅項);
};

export const 營業總額BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.營業總額);
};

export const Turnover_T_3BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["Turnover-T-3"]);
};

export const Turnover_T_2BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["Turnover-T-2"]);
};

export const Turnover_T_1BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["Turnover-T-1"]);
};

export const Turnover_T_0BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["Turnover-T-0"]);
};

export const 銷售成本BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.銷售成本);
};

export const 股東應佔溢利BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.股東應佔溢利);
};
export const NetProfit_T_3BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["NetProfit-T-3"]);
};

export const NetProfit_T_2BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["NetProfit-T-2"]);

};
export const NetProfit_T_1BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["NetProfit-T-1"]);

};
export const NetProfit_T_0BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["NetProfit-T-0"]);
};

export const ocf_t_1BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["OCF-T-1"]);
};

export const ocfBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.OCF);
};

export const ocf_ocf_t_1BodyTemplate = (rowData) => {
    return percent(rowData["OCF/OCF-T-1"]);
};

export const ceBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.CE);
};

export const eBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.E);
};

export const vdcaBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.VDCA);
};

export const tlBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.TL);
};

export const 單位BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.單位);
};

export const 存貨BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.存貨);
};

export const 應收賬BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.應收賬);
};

export const 現金BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.現金);
};

export const 流動資產BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.流動資產);
};

export const 總資產BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.總資產);
};

export const 非流動負債BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.非流動負債);
};

export const 流動負債BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.流動負債);
};

export const VDCACurrentRatioBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.VDCACurrentRatio);
};

export const CE_FCFBodyTemplate = (rowData) => {
    return percent(rowData.CE_FCF);
};

export const 少數股東權益BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.少數股東權益);
};

export const 市值_港元BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["市值(港元)"]);
};

export const NetCash價BodyTemplate = (rowData) => {
    return percent(rowData["Net Cash價"]);
};

export const 市盈率BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.市盈率);
};

export const 股息率BodyTemplate = (rowData) => {
    return percent(rowData.股息率);
};

export const 派息比率BodyTemplate = (rowData) => {
    return percent(rowData.派息比率);
};

export const 每股派息BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.每股派息);
};

export const coeRankingBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.COERanking);
};

export const interestBiggeraboveBodyTemplate = (rowData) => {
    return percent(rowData.interestBiggerabove);
};