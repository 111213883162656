var numeral = require('numeral');

export function percent(value) {
    return numeral(value).format('0,0.00%'); //1,000.55%
}

export function hasDecimalFormat(value) {
    let temp = value;

    if (temp === 0 || temp === null) {
        return "-"
    }else{
        return numeral(temp).format('(0,0.00)');
    }
}

export function withoutDecimal(value) {
    let temp = value;

    if (temp === 0 || temp === null) {
        return "-"
    }else{
        return numeral(temp).format('(0,0)');
    }
}

export function currencyRate(value) {
    let temp = value;
    if (temp === null) {
        return "-"
    }else{
        return numeral(temp).format('0.00');
    }
}



export function mixFormat(value) {

    let temp = value;

    if (temp === 0 || temp === null) {
        return "-"
    }
    // console.log(temp);
    if (hasDecimal(value)) {
        // if (value < 0) {
        return numeral(temp).format('(0,0.00)');
        // return numeral(temp).format('0,0.00');

        // } else {
        //     return numeral(temp).format('0,0.00');
        // }
    } else {
        // if (value < 0  ) {
        return numeral(temp).format('(0,0)');
        // return numeral(temp).format('0,0');

        // } else if (value >= 1000) {
        //     return numeral(temp).format('0,0');
        // }


    }


}

function hasDecimal(num) {
    return num.toString().includes('.');
}

// export function currencyRate(num) {
//     let rate = mixFormat(num)
//     return numeral(rate).format('0.00')

// }

// export function withoutDecimal(num) {
//     let rate = mixFormat(num)
//     return numeral(rate).format('(0,0)')
// }

