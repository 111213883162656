import React from "react";

import Loading from "../components/Loading";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useSelector } from "react-redux"

export const ProfileComponent = () => {
  // const { user } = useAuth0();
  const { user } = useSelector(state => state.auth)


  return (
    <div>
      <h2>{user.name}</h2>
      <p className="lead text-muted">{user.email}</p>
      <p>{JSON.stringify(user, null, 2)}</p>
    </div>
  );
};

export default withAuthenticationRequired(ProfileComponent, {
  onRedirecting: () => <Loading />,
});
