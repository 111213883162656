import { useEffect, useState, useRef, useContext } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import calculateStockInfo from "../utils/calculateStockInfo.js";
import * as f from "../utils/formatStockInfo.js";
import Chart from "react-apexcharts";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { MultiSelect } from "primereact/multiselect";
import { Button } from "primereact/button";
import { saveAs } from "file-saver";
import { Dropdown } from "primereact/dropdown";
import moment from "moment";
import ThemeContext from "../components/ThemeContext.js";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "../components/LoginButton.js";
import "./HomePage.css";
import secureLocalStorage from "react-secure-storage";
import numeral from "numeral";
import { useSelector } from "react-redux";
import {
  fetchStockData,
  fetchDataLatestTime,
} from "../utils/fetchStockDataAndTime.js";
import TitleBar from "../components/TitleBar.js";

export default function HomePage() {
  const [stockData, setStockData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshData, setRefreshData] = useState(false);
  const [filters, setFilters] = useState({
    股票編號: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    公司名稱: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    行業: { value: null, matchMode: FilterMatchMode.IN },
    現價: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    netCash: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    打劫價: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    NCA: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    上限價: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    市場價: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    筍價: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    現價回報率_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    COE_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    FCF: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "每股自由現金流(港元)": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    市值_億: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    _52週高: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    _52週低: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "股數(股)": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    報告貨幣匯率: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    總負債: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    每手股數: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "每手價格(港元)": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    退回稅項: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    營業總額: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "Turnover-T-3": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "Turnover-T-2": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "Turnover-T-1": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "Turnover-T-0": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    銷售成本: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    股東應佔溢利: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "NetProfit-T-3": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "NetProfit-T-2": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "NetProfit-T-1": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "NetProfit-T-0": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "OCF-T-1": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    OCF: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    OCF_OCF_T_1_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    CE: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    E: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    VDCA: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    TL: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    單位: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    存貨: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    應收賬: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    現金: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    流動資產: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    總資產: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    非流動負債: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    流動負債: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    VDCACurrentRatio: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    CE_FCF_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    少數股東權益: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    "市值(港元)": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    NetCash價_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    市盈率: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    股息率_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    派息比率_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    每股派息: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    COE4年正數: { value: null, matchMode: FilterMatchMode.EQUALS },
    COE4年連升: { value: null, matchMode: FilterMatchMode.EQUALS },
    COE最近一年有增長: { value: null, matchMode: FilterMatchMode.EQUALS },
    現價smaller打劫價: { value: null, matchMode: FilterMatchMode.EQUALS },
    DCA_fcf_cl_0: { value: null, matchMode: FilterMatchMode.EQUALS },
    VDCACurrentRatioBigger1: { value: null, matchMode: FilterMatchMode.EQUALS },
    COERanking: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    現價回報率BiggerCoe_1: { value: null, matchMode: FilterMatchMode.EQUALS },
    NCA_3XFCFBiggerNetCash: { value: null, matchMode: FilterMatchMode.EQUALS },
    NCA_3XFCFBigger打劫價: { value: null, matchMode: FilterMatchMode.EQUALS },
    過去3年Turnover都有20增長: { value: null, matchMode: FilterMatchMode.EQUALS },
    過去3年NetProfit都有20增長: { value: null, matchMode: FilterMatchMode.EQUALS }, 
    interestBiggerabove_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
    OCF比較30: { value: null, matchMode: FilterMatchMode.EQUALS },
    OCF比較50: { value: null, matchMode: FilterMatchMode.EQUALS },
    OCF2: { value: null, matchMode: FilterMatchMode.EQUALS },
    OCF正數比較30: { value: null, matchMode: FilterMatchMode.EQUALS },
    OCF正數比較50: { value: null, matchMode: FilterMatchMode.EQUALS },
    OCF正數比較22: { value: null, matchMode: FilterMatchMode.EQUALS },
  });

  const [industries, setIndustries] = useState(null);
  const [totalRecords, setTotalRecords] = useState(0); // Total records count
  const [multiSortMeta, setMultiSortMeta] = useState([]);
  const [firstState, setFirstState] = useState(0);
  const [sortFieldState, setSortFieldState] = useState("");
  const [sortOrderState, setSortOrderState] = useState(1);
  // const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const { getAccessTokenSilently, loginWithRedirect } = useAuth0();
  const { isAuthenticated } = useSelector((state) => state.auth);

  // const fetchStockDataUrl = `${process.env.REACT_APP_ST_API_URL}/`;
  // const fetchDataLatestTimeUrl = `${process.env.REACT_APP_ST_API_URL}/time`;
  let orgStockData = useRef(null);
  let orgLatestTime = useRef(null);
  const dt = useRef(null);
  const { theme } = useContext(ThemeContext);

  // async function fetchStockData() {
  //   try {
  //     const token = await getAccessTokenSilently();

  //     const res = await fetch(fetchStockDataUrl, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (res.status === 200) {
  //       return await res.json();
  //     } else {
  //       const errorText = await res.text(); // Get error response body if available
  //       throw new Error(
  //         `Request failed with status ${res.status}: ${errorText}`
  //       );
  //     }
  //   } catch (err) {
  //     console.error("Error fetching stock data:", err);
  //   }
  // }

  // async function fetchDataLatestTime() {
  //   try {
  //     const token = await getAccessTokenSilently();

  //     const res = await fetch(fetchDataLatestTimeUrl, {
  //       headers: {
  //         Authorization: `Bearer ${token}`,
  //       },
  //     });

  //     if (res.status === 200) {
  //       return await res.json();
  //     } else {
  //       throw new Error(`Request failed with status ${res.status}`);
  //     }
  //   } catch (err) {
  //     console.error("Error fetching data latest time:", err);
  //     throw err;
  //   }
  // }

  //OCF Filter////////
  const [scoreFilter01] = useState(["1", "-"]);

  const scoreFilterFilterItemTemplate = (option) => {
    return <div>{option}</div>;
  };

  const scoreFilter_0_1RowFilterTemplate = (options) => {
    return (
      <Dropdown
        value={options.value}
        options={scoreFilter01}
        onChange={(e) => options.filterApplyCallback(e.value)}
        itemTemplate={scoreFilterFilterItemTemplate}
        placeholder="Select One"
        className="p-column-filter"
        showClear
        style={{ minWidth: "12rem" }}
      />
    );
  };
  //OCF Filter///

  //Charts options////
  const options = {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {
      bar: {
        colors: {
          ranges: [
            {
              from: -Infinity,
              to: 0,
              color: "#EC4899",
            },
            {
              from: 0,
              to: Infinity,
              color: "#3B82F6",
            },
          ],
        },
        horizontal: false,
        showBarLine: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    yaxis: {
      labels: {
        show: false,
      },
    },
    xaxis: {
      labels: {
        show: false,
      },
    },
    grid: {
      show: false,
    },
    tooltip: {
      enabled: false,
    },
  };
  //Charts options////

  //Industries Filter/////
  const getIndustries = (formatResult) => {
    if (formatResult) {
      return setIndustries([...new Set(formatResult.map((i) => i.行業))]);
    }
  };

  const industriesItemTemplate = (option) => {
    return (
      <div className="flex align-items-center gap-2">
        <span>{option}</span>
      </div>
    );
  };

  const industryRowFilterTemplate = (options) => {
    return (
      <MultiSelect
        value={options.value}
        options={industries}
        itemTemplate={industriesItemTemplate}
        onChange={(e) => options.filterApplyCallback(e.value)}
        placeholder="任何"
        className="p-column-filter"
        maxSelectedLabels={1}
        style={{ minWidth: "14rem" }}
      />
    );
  };
  //Industries Filter/////

  //Clear Filter////

  const clearFilter = () => {
    setFilters({
      股票編號: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      公司名稱: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
      行業: { value: null, matchMode: FilterMatchMode.IN },
      現價: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      netCash: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      打劫價: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      NCA: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      上限價: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      市場價: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      筍價: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      現價回報率_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      COE_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      FCF: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "每股自由現金流(港元)": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      市值_億: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      _52週高: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      _52週低: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "股數(股)": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      報告貨幣匯率: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      總負債: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      每手股數: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "每手價格(港元)": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      退回稅項: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      營業總額: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "Turnover-T-3": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "Turnover-T-2": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "Turnover-T-1": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "Turnover-T-0": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      銷售成本: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      股東應佔溢利: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "NetProfit-T-3": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "NetProfit-T-2": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "NetProfit-T-1": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "NetProfit-T-0": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "OCF-T-1": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      OCF: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      OCF_OCF_T_1_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      CE: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      E: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      VDCA: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      TL: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      單位: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      存貨: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      應收賬: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      現金: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      流動資產: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      總資產: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      非流動負債: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      流動負債: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      VDCACurrentRatio: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      CE_FCF_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      少數股東權益: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      "市值(港元)": { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      NetCash價_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      市盈率: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      股息率_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      派息比率_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      每股派息: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      COE4年正數: { value: null, matchMode: FilterMatchMode.EQUALS },
      COE4年連升: { value: null, matchMode: FilterMatchMode.EQUALS },
      COE最近一年有增長: { value: null, matchMode: FilterMatchMode.EQUALS },
      現價smaller打劫價: { value: null, matchMode: FilterMatchMode.EQUALS },
      DCA_fcf_cl_0: { value: null, matchMode: FilterMatchMode.EQUALS },
      VDCACurrentRatioBigger1: { value: null, matchMode: FilterMatchMode.EQUALS },
      COERanking: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      現價回報率BiggerCoe_1: { value: null, matchMode: FilterMatchMode.EQUALS },
      NCA_3XFCFBiggerNetCash: { value: null, matchMode: FilterMatchMode.EQUALS },
      NCA_3XFCFBigger打劫價: { value: null, matchMode: FilterMatchMode.EQUALS },
      過去3年Turnover都有20增長: { value: null, matchMode: FilterMatchMode.EQUALS },
      過去3年NetProfit都有20增長: { value: null, matchMode: FilterMatchMode.EQUALS }, 
      interestBiggerabove_multiply100: { operator: FilterOperator.AND, constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }] },
      OCF比較30: { value: null, matchMode: FilterMatchMode.EQUALS },
      OCF比較50: { value: null, matchMode: FilterMatchMode.EQUALS },
      OCF2: { value: null, matchMode: FilterMatchMode.EQUALS },
      OCF正數比較30: { value: null, matchMode: FilterMatchMode.EQUALS },
      OCF正數比較50: { value: null, matchMode: FilterMatchMode.EQUALS },
      OCF正數比較22: { value: null, matchMode: FilterMatchMode.EQUALS },
    });
    clearSorting();
    secureLocalStorage.removeItem("datatable_sort_summary");
    secureLocalStorage.removeItem("datatable_filters_summary");
  };

  const customSortFunction = (event) => {
    const { multiSortMeta, data } = event;
    // Sort the data based on multiSortMeta
    let sortedDataCopy = [...data];

    sortedDataCopy.sort((a, b) => {
      for (let i = 0; i < multiSortMeta.length; i++) {
        const sortMeta = multiSortMeta[i];
        const { field, order } = sortMeta;

        // const convertedA = numeral(a[field].replace("%", "")).value();
        // const convertedB = numeral(b[field].replace("%", "")).value();

        // Special case: comparing "-" and "1"
        if (parseInt(a[field]) > 0 && b[field] === "-") return order;
        if (parseInt(b[field]) > 0 && a[field] === "-") return -order;

        // if (convertedA > convertedB) return order;
        // if (convertedA < convertedB) return -order;
      }
      return 0;
    });

    return sortedDataCopy;
  };

  const customOnSort = (event) => {
    const { multiSortMeta, sortOrder, sortField } = event;
    setMultiSortMeta(multiSortMeta);
    setSortFieldState(sortField);
    setSortOrderState(sortOrder);
    setFirstState(0);
    secureLocalStorage.setItem("datatable_sort_summary", JSON.stringify(event));
  };

  const clearSorting = () => {
    setStockData(orgStockData.current);
    setMultiSortMeta([]);
  };
  //Clear Filter////

  //refresh Data////
  function refreshingData() {
    setLoading(true);
    // clearFilter();
    setRefreshData(true);
  }

  const onFilterChange = (field, value) => {
    setFilters(value);
    secureLocalStorage.setItem("datatable_filters_summary", JSON.stringify(value));
  };

  const onFilter = (event) => {
    event && setTotalRecords(event.length ? event.length : 0);
  };

  function convertValue(value) {
    // Remove any commas for easier conversion

    let v = value.replace(/,/g, "");

    // Check if the value is enclosed in parentheses
    if (v.startsWith("(") && v.endsWith(")")) {
      // Remove the parentheses and convert to negative
      return -parseFloat(v.slice(1, -1));
    } else {
      // Convert to float or integer based on presence of decimal point
      if (v.endsWith("%")) {
        return parseFloat(v) / 100;
      } else {
        return parseFloat(v) || 0; // Convert to number or default to 0
      }
    }
  }

  // Method to export data to Excel
  const exportToExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(stockData);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
      });
      saveAs(data, "stock_data.xlsx");
    });
  };

  // Method to export data to PDF
  const exportToPDF = () => {
    import("jspdf").then((jspdf) => {
      import("jspdf-autotable").then(() => {
        const doc = new jspdf.default(0, 0);
        doc.autoTable({ html: "#stockDataTable" });
        doc.save("stock_data.pdf");
      });
    });
  };

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     // Check if the pressed key is F5
  //     if (event.key === 'F5' || event.keyCode === 116) {
  //       // event.preventDefault(); // Prevent the default F5 action (refresh)
  //       clearFilter()
  //     }
  //   };

  //   // Add event listener when component mounts
  //   window.addEventListener('keydown', handleKeyDown);

  //   // Cleanup event listener when component unmounts
  //   return () => {
  //     window.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);

  useEffect(() => {
    async function getOrgStockData() {
      const data = await fetchStockData(
        getAccessTokenSilently,
        loginWithRedirect
      );
      // orgStockData.current = data;
      let calResult = await calculateStockInfo(data);
      let formatResult = await f.formatStockInfo(calResult);
      orgStockData.current = formatResult;
      getIndustries(formatResult);
      setStockData(formatResult);
      setTotalRecords(formatResult.length);
      if (orgStockData.current) {
        const dataLatestTime = await fetchDataLatestTime(
          getAccessTokenSilently,
          loginWithRedirect
        );
        orgLatestTime.current = dataLatestTime;
      }
      setLoading(false);
      setRefreshData(false);
    }
    if (isAuthenticated) {
      getOrgStockData();
      const storedFilters = secureLocalStorage.getItem("datatable_filters_summary");
      if (storedFilters) {
        setFilters(JSON.parse(storedFilters));
      }

      const storedSort = secureLocalStorage.getItem("datatable_sort_summary");
      if (storedSort) {
        const { multiSortMeta, sortOrder, sortField } = JSON.parse(storedSort);
        setMultiSortMeta(multiSortMeta);
        setSortFieldState(sortField);
        setSortOrderState(sortOrder);
        setFirstState(0);
      }
    }
  }, [refreshData]);

  return isAuthenticated ? (
    <div className={`App theme-${theme} height90`}>
      <>

        <TitleBar
          title="Summary"
          loading={loading}
          totalRecords={totalRecords}
          orgLatestTime={orgLatestTime.current}
          refreshingData={refreshingData}
          clearFilter={clearFilter}
        ></TitleBar>

        {/* <div className="data-header text-color">
          <div className="d-flex align-item-center">
            <Button
              type="button"
              icon="pi pi-refresh"
              text
              raised
              severity="success"
              onClick={refreshingData}
            />
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Clear"
              severity="secondary"
              raised
              text
              onClick={clearFilter}
            />

            <span className="text-color">
              &nbsp;<i className="pi pi-server"></i> Total of records:
              {!loading ? (
                <>
                  <span> {totalRecords}</span>
                </>
              ) : (
                <span>
                  {" "}
                  <i className="pi pi-spin pi-spinner"></i>
                </span>
              )}
            </span>
          </div>

          <h2>
            Summary <i className="pi pi-chart-line"></i>
          </h2>

          <span className="text-color">
            <i className="pi pi-history"></i> Last updated:
            {!loading ? (
              <>
                <span>
                  {" "}
                  {moment(orgLatestTime.current).format(
                    "DD MMM YYYY, HH:mm:ss"
                  )}
                </span>
              </>
            ) : (
              <span>
                {" "}
                <i className="pi pi-spin pi-spinner"></i>
              </span>
            )}
          </span>
        </div> */}

        {/* <div className="export-buttons">
                    <Button type="button" onClick={exportToExcel} label="Export to Excel" className="p-button-success" />
                    <Button type="button" onClick={exportToPDF} label="Export to PDF" className="p-button-warning" />
                </div> */}
        <div className="card">
          <DataTable
            ref={dt}
            value={stockData}
            scrollable
            scrollHeight="77vh"
            resizableColumns
            showGridlines
            stripedRows
            paginator
            rows={20}
            rowsPerPageOptions={[5, 10, 25, 50]}
            filters={filters}
            filterDisplay="menu"
            loading={loading}
            onValueChange={onFilter}
            onFilter={(e) => onFilterChange(e.field, e.filters)}
            removableSort
            sortMode="multiple"
            multiSortMeta={multiSortMeta}
            sortField={sortFieldState}
            sortOrder={sortOrderState}
            first={firstState}
            onSort={customOnSort}
            paginatorTemplate="RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink"
            currentPageReportTemplate="{first} to {last} of {totalRecords}"
            emptyMessage="No stock data found."
          >
            <Column
              field="股票編號"
              header="股票編號"
              footer="股票編號"
              frozen
              filter
              showFilterMenu={true}
              sortable
              filterDelay={1000}
              filterPlaceholder="股票編號"
            ></Column>
            <Column
              field="公司名稱"
              header="公司名稱"
              footer="公司名稱"
              frozen
              filter
              sortable
              showFilterMenu={true}
              filterDelay={1000}
              filterPlaceholder="公司名稱"
            ></Column>
            <Column
              field="行業"
              header="行業"
              footer="行業"
              showFilterMenu={true}
              filter
              sortable
              showFilterMatchModes={false}
              filterElement={industryRowFilterTemplate}
              filterMenuStyle={{ width: "16rem" }}
            ></Column>
            <Column
              field="上市日期"
              header="上市日期"
              footer="上市日期"
              sortable
            ></Column>
            <Column
              field="現價"
              header="現價"
              footer="現價"
              sortable
              dataType="numeric"
              filter
              body={f.現價BodyTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="netCash"
              header="Net Cash"
              footer="Net Cash"
              showFilterMenu={true}
              sortable
              dataType="numeric"
              filter
              body={f.netCashBodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="打劫價"
              header="打劫價"
              footer="打劫價"
              sortable
              dataType="numeric"
              filter
              body={f.打劫價BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="NCA"
              header="NCA"
              footer="NCA"
              sortable
              dataType="numeric"
              filter
              body={f.ncaBodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="上限價"
              header="上限價"
              footer="上限價"
              sortable
              dataType="numeric"
              filter
              body={f.上限價BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="市場價"
              header="市場價"
              footer="市場價"
              sortable
              dataType="numeric"
              filter
              body={f.市場價BodyTemplate}
              // sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="筍價"
              header="筍價"
              footer="筍價"
              sortable
              dataType="numeric"
              filter
              body={f.筍價BodyTemplate}
              // sortFunction={customSortFunction}  
              className="text-align-right"
            ></Column>
            <Column
              field="現價回報率_multiply100"
              header="現價回報率"
              footer="現價回報率"
              sortable
              dataType="numeric"
              filter
              body={f.現價回報率BodyTemplate}
              // sortFunction={customSortFunction}  
              className="text-align-right"
            ></Column>
            <Column
              field="COE_multiply100"
              header="COE"
              footer="COE"
              sortable
              dataType="numeric"
              filter
              body={f.coeBodyTemplate}
              // sortFunction={customSortFunction}  
              className="text-align-right"
            ></Column>
            <Column
              header="COE 4年圖"
              footer="COE 4年圖"
              body={(row) => {
                return row.COESpark && row.COESpark.length > 0 ? (
                  <Chart
                    series={row.COESpark}
                    options={options}
                    type="bar"
                    height={10}
                    width={100}
                  />
                ) : (
                  <div>No data available</div>
                );
              }}
            ></Column>
            <Column
              field="FCF"
              header="FCF"
              footer="FCF"
              sortable
              dataType="numeric"
              filter
              body={f.fcfBodyTemplate}
              // sortFunction={customSortFunction}  
              className="text-align-right"
            ></Column>
            <Column
              header="FCF 4年圖"
              footer="FCF 4年圖"
              body={(row) => (
                <Chart
                  series={row.FCFSpark}
                  options={options}
                  type="bar"
                  height={10}
                  width={100}
                />
              )}
            ></Column>
            <Column
              field="每股自由現金流(港元)"
              header="每股自由現金流(港元)"
              footer="每股自由現金流(港元)"
              sortable
              dataType="numeric"
              filter
              body={f.每股自由現金流_港元BodyTemplate}
              // sortFunction={customSortFunction}  
              className="text-align-right"
            ></Column>
            <Column
              field="市值_億"
              header="市值(億)"
              footer="市值(億)"
              sortable
              dataType="numeric"
              filter
              body={f.市值_億BodyTemplate}
              // sortFunction={customSortFunction}  
              className="text-align-right"
            ></Column>
            <Column
              field="_52週高"
              header="52週高"
              footer="52週高"
              sortable
              dataType="numeric"
              filter
              body={f._52週高BodyTemplate}
              // sortFunction={customSortFunction}  

              className="text-align-right"
            ></Column>
            <Column
              field="_52週低"
              header="52週低"
              footer="52週低"
              sortable
              dataType="numeric"
              filter
              body={f._52週低BodyTemplate}
              // sortFunction={customSortFunction}  
              className="text-align-right"
            ></Column>
            <Column
              field="股數(股)"
              header="股數(股)"
              footer="股數(股)"
              sortable
              dataType="numeric"
              filter
              body={f.股數_股BodyTemplate}
              // sortFunction={customSortFunction}  
              className="text-align-right"
            ></Column>
            <Column
              field="報告貨幣"
              header="報告貨幣"
              footer="報告貨幣"
              sortable
            ></Column>
            <Column
              field="報告貨幣匯率"
              header="報告貨幣匯率"
              footer="報告貨幣匯率"
              dataType="numeric"
              filter
              body={f.報告貨幣匯率BodyTemplate}
              // sortFunction={customSortFunction}               
              className="text-align-right"
            ></Column>
            <Column
              field="總負債"
              header="總負債"
              footer="總負債"
              sortable
              dataType="numeric"
              filter
              body={f.總負債BodyTemplate}
              // sortFunction={customSortFunction}  
              className="text-align-right"
            ></Column>
            <Column
              field="每手股數"
              header="每手股數"
              footer="每手股數"
              sortable
              dataType="numeric"
              filter
              body={f.每手股數BodyTemplate}
              // sortFunction={customSortFunction}  
              className="text-align-right"
            ></Column>
            <Column
              field="財報更新日期"
              header="財報更新日期"
              footer="財報更新日期"
              sortable
            ></Column>
            <Column
              field="每手價格(港元)"
              header="每手價格(港元)"
              footer="每手價格(港元)"
              sortable
              dataType="numeric"
              filter
              body={f.每手價格_港元BodyTemplate}
              // sortFunction={customSortFunction}  
              className="text-align-right"
            ></Column>
            <Column
              field="最近財報日期(BS & PL)"
              header="最近財報日期(BS & PL)"
              footer="最近財報日期(BS & PL)"
              sortable
            ></Column>
            <Column
              field="年報類別(BS & PL)"
              header="年報類別(BS & PL)"
              footer="年報類別(BS & PL)"
              sortable
            ></Column>
            <Column
              field="最近財報日期(CF)"
              header="最近財報日期(CF)"
              footer="最近財報日期(CF)"
              sortable
            ></Column>
            <Column
              field="年報類別(CF)"
              header="年報類別(CF)"
              footer="年報類別(CF)"
              sortable
            ></Column>
            <Column
              field="BS&PL財報日期與CF不同"
              header="BS&PL財報日期與CF不同"
              footer="BS&PL財報日期與CF不同"
              sortable
            ></Column>
            <Column
              field="退回稅項"
              header="退回稅項"
              footer="退回稅項"
              sortable
              dataType="numeric"
              filter
              body={f.退回稅項BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="營業總額"
              header="營業總額"
              footer="營業總額"
              sortable
              dataType="numeric"
              filter
              body={f.營業總額BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="Turnover-T-3"
              header="Turnover-T-3"
              footer="Turnover-T-3"
              sortable
              dataType="numeric"
              filter
              body={f.Turnover_T_3BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="Turnover-T-2"
              header="Turnover-T-2"
              footer="Turnover-T-2"
              sortable
              dataType="numeric"
              filter
              body={f.Turnover_T_2BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="Turnover-T-1"
              header="Turnover-T-1"
              footer="Turnover-T-1"
              sortable
              dataType="numeric"
              filter
              body={f.Turnover_T_1BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="Turnover-T-0"
              header="Turnover-T-0"
              footer="Turnover-T-0"
              sortable
              dataType="numeric"
              filter
              body={f.Turnover_T_0BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="銷售成本"
              header="銷售成本"
              footer="銷售成本"
              sortable
              dataType="numeric"
              filter
              body={f.銷售成本BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="股東應佔溢利"
              header="股東應佔溢利"
              footer="股東應佔溢利"
              sortable
              dataType="numeric"
              filter
              body={f.股東應佔溢利BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="NetProfit-T-3"
              header="NetProfit-T-3"
              footer="NetProfit-T-3"
              sortable
              dataType="numeric"
              filter
              body={f.NetProfit_T_3BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="NetProfit-T-2"
              header="NetProfit-T-2"
              footer="NetProfit-T-2"
              sortable
              dataType="numeric"
              filter
              body={f.NetProfit_T_2BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="NetProfit_T_1"
              header="NetProfit-T-1"
              footer="NetProfit-T-1"
              sortable
              dataType="numeric"
              filter
              body={f.NetProfit_T_1BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="NetProfit-T-0"
              header="NetProfit-T-0"
              footer="NetProfit-T-0"
              sortable
              dataType="numeric"
              filter
              body={f.NetProfit_T_0BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="OCF-T-1"
              header="OCF-T-1"
              footer="OCF-T-1"
              sortable
              dataType="numeric"
              filter
              body={f.ocf_t_1BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="OCF"
              header="OCF"
              footer="OCF"
              sortable
              dataType="numeric"
              filter
              body={f.ocfBodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="OCF_OCF_T_1_multiply100"
              header="OCF/OCF-T-1"
              footer="OCF/OCF-T-1"
              sortable
              dataType="numeric"
              filter
              body={f.ocf_ocf_t_1BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="CE"
              header="CE"
              footer="CE"
              sortable
              dataType="numeric"
              filter
              body={f.ceBodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="E"
              header="E"
              footer="E"
              sortable
              dataType="numeric"
              filter
              body={f.eBodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="VDCA"
              header="VDCA"
              footer="VDCA"
              sortable
              dataType="numeric"
              filter
              body={f.vdcaBodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="TL"
              header="TL"
              footer="TL"
              sortable
              dataType="numeric"
              filter
              body={f.tlBodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="單位"
              header="單位"
              footer="單位"
              sortable
              dataType="numeric"
              filter
              body={f.單位BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="存貨"
              header="存貨"
              footer="存貨"
              sortable
              dataType="numeric"
              filter
              body={f.存貨BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="應收賬"
              header="應收賬"
              footer="應收賬"
              sortable
              dataType="numeric"
              filter
              body={f.應收賬BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="現金"
              header="現金"
              footer="現金"
              sortable
              dataType="numeric"
              filter
              body={f.現金BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="流動資產"
              header="流動資產"
              footer="流動資產"
              sortable
              dataType="numeric"
              filter
              body={f.流動資產BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="總資產"
              header="總資產"
              footer="總資產"
              sortable
              dataType="numeric"
              filter
              body={f.總資產BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="非流動負債"
              header="非流動負債"
              footer="非流動負債"
              sortable
              dataType="numeric"
              filter
              body={f.非流動負債BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="流動負債"
              header="流動負債"
              footer="流動負債"
              sortable
              dataType="numeric"
              filter
              body={f.流動負債BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="VDCACurrentRatio"
              header="VDCA Current Ratio (1.5-3)"
              footer="VDCA Current Ratio (1.5-3)"
              sortable
              dataType="numeric"
              filter
              body={f.VDCACurrentRatioBodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="CE_FCF_multiply100"
              header="CE / FCF"
              footer="CE / FCF"
              sortable
              dataType="numeric"
              filter
              body={f.CE_FCFBodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="少數股東權益"
              header="少數股東權益"
              footer="少數股東權益"
              sortable
              dataType="numeric"
              filter
              body={f.少數股東權益BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="市值(港元)"
              header="市值(港元)"
              footer="市值(港元)"
              sortable
              dataType="numeric"
              filter
              body={f.市值_港元BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="NetCash價_multiply100"
              header="Net Cash價"
              footer="Net Cash價"
              sortable
              dataType="numeric"
              filter
              body={f.NetCash價BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="市盈率"
              header="市盈率"
              footer="市盈率"
              sortable
              dataType="numeric"
              filter
              body={f.市盈率BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="股息率_multiply100"
              header="股息率"
              footer="股息率"
              sortable
              dataType="numeric"
              filter
              body={f.股息率BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="派息比率_multiply100"
              header="派息比率"
              footer="派息比率"
              sortable
              dataType="numeric"
              filter
              body={f.派息比率BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="每股派息"
              header="每股派息"
              footer="每股派息"
              dataType="numeric"
              sortable
              filter
              body={f.每股派息BodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column field="股息" header="股息" footer="股息" sortable></Column>
            <Column
              field="股息除淨日"
              header="股息除淨日"
              footer="股息除淨日"
              sortable
              className="text-align-right"
            ></Column>
            <Column
              field="COE4年正數"
              header="COE 4年正數"
              footer="COE 4年正數"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="COE4年連升"
              header="COE 4年連升"
              footer="COE 4年連升"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="COE最近一年有增長"
              header="COE 最近一年有增長"
              footer="COE 最近一年有增長"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="現價smaller打劫價"
              header="現價<打劫價"
              footer="現價<打劫價"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="Profitability"
              header="Profitability"
              footer="Profitability"
              sortable
              sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="DCA_fcf_cl_0"
              header="DCA+fcf-cl>0"
              footer="DCA+fcf-cl>0"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="VDCACurrentRatioBigger1"
              header="VDCA Current Ratio >1"
              footer="VDCA Current Ratio >1"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="COERanking"
              header="COE Ranking"
              footer="COE Ranking"
              sortable
              dataType="numeric"
              filter
              body={f.coeRankingBodyTemplate}
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="現價回報率BiggerCoe_1"
              header="現價回報率>coe*1"
              footer="現價回報率>coe*1"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="NCA_3XFCFBiggerNetCash"
              header="NCA+3XFCF > Net Cash"
              footer="NCA+3XFCF > Net Cash"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="NCA_3XFCFBigger打劫價"
              header="NCA+3XFCF > 打劫價"
              footer="NCA+3XFCF > 打劫價"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="過去3年Turnover都有20增長"
              header="過去3年Turnover都有20%增長"
              footer="過去3年Turnover都有20%增長"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="過去3年NetProfit都有20增長"
              header="過去3年Net Profit都有20%增長"
              footer="過去3年Net Profit都有20%增長"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="interestBiggerabove_multiply100"
              header="interest > above"
              footer="interest > above"
              sortable
              dataType="numeric"
              filter
              body={f.interestBiggeraboveBodyTemplate}
            
              //sortFunction={customSortFunction}
              className="text-align-right"
            ></Column>
            <Column
              field="OCF比較30"
              header="OCF比較30%"
              footer="OCF比較30%"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="OCF比較50"
              header="OCF比較50%"
              footer="OCF比較50%"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="OCF2"
              header="OCF2(100%)"
              footer="OCF2(100%)"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="OCF正數比較30"
              header="OCF正數比較30%"
              footer="OCF正數比較30%"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="OCF正數比較50"
              header="OCF正數比較50%"
              footer="OCF正數比較50%"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
            <Column
              field="OCF正數比較22"
              header="OCF正數比較22"
              footer="OCF正數比較22"
              sortable
              sortFunction={customSortFunction}
              showFilterMenu={true}
              filter
              filterElement={scoreFilter_0_1RowFilterTemplate}
              className="text-align-right"
            ></Column>
          </DataTable>
        </div>
      </>
    </div>
  ) : (
    <LoginButton />
  );
}
