export default async function calculateStockInfo(orgStockData) {

    if (orgStockData) {

        const calculatedStocks = await orgStockData.map(s => {

            let netCash; //=IFERROR(([@現金]-[@TL])/[@[股數(股)]]*[@單位],0)
            let VDCACurrentRatio; //=IFERROR([@VDCA]/[@流動負債],0)
            let CE_FCF; //=IF([@CE]=0, 0,-[@CE]/[@FCF])
            let COE4年正數; //=IF(AND([@[COE_Y-3]]>0, [@[COE_Y-2]]> 0, [@[COE_Y-1]]>0, [@COE]>0),1,0)
            let COE4年連升; //=IF(AND([@COE]>[@[COE_Y-1]],[@[COE_Y-1]]>[@[COE_Y-2]],[@[COE_Y-2]]>[@[COE_Y-3]]),1,0)
            let COE最近一年有增長; //=IF([@COE]>[@[COE_Y-1]],1,0)
            let 現價smaller打劫價; //=IF(ISERROR([@[現價 (Excel)]]),"", IF([@[現價 (Excel)]]<[@打劫價],1,0))
            let Profitability; //=IF([@[CE / FCF]]<0,0,IF([@[CE / FCF]]<20%,2,IF([@[CE / FCF]]<40%, 1, 0)))
            let DCA_fcf_cl_0; //=IF(([@流動資產]-(0.5*[@存貨])-(0.3*[@應收賬])+[@FCF]-[@非流動負債])>0,1,0)
            let VDCACurrentRatioBigger1; //=IF([@[VDCA Current Ratio (1.5-3)]]>1,1,0)
            let COERanking; // =IF([@COE]<0,-1,IF([@COE]>0,IF([@COE]<10%,1,IF([@COE]>10%,IF([@COE]<20%,2,0)))))
            let 現價回報率BiggerCoe_1; //=IF([@現價回報率]>[@COE],1,0)
            let NCA_3XFCFBiggerNetCash; //=IF([@NCA]+3*[@FCF]>[@[Net Cash]],1,0)
            let NCA_3XFCFBigger打劫價; //=IF([@NCA]+3*[@[FCF]]>[@打劫價],1,0)
            let 過去3年Turnover都有20增長; // =IFERROR(IF(AND([@[Turnover-T-0]]/[@[Turnover-T-1]]>=(1+$CO$3), [@[Turnover-T-1]]/[@[Turnover-T-2]]>=(1+$CO$3),[@[Turnover-T-2]]/[@[Turnover-T-3]]>=(1+$CO$3)), 1, 0),0)
            let 過去3年NetProfit都有20增長; // =IFERROR(IF(AND([@[NetProfit-T-0]]/[@[NetProfit-T-1]]>=(1+$CP$3), [@[NetProfit-T-1]]/[@[NetProfit-T-2]]>=(1+$CP$3),[@[NetProfit-T-2]]/[@[NetProfit-T-3]]>=(1+$CP$3)), 1, 0),0)
            let interestBiggerabove; //=IF([@每股派息]/[@現價]>$EE$3,[@每股派息]/[@現價],"")
            let OCF比較30; //=IF([@[OCF/OCF-T-1]]>$EF$3,1,0) 30%
            let OCF比較50; //=IF([@[OCF/OCF-T-1]]>$EG$3,1,0) 50%
            let OCF2;  //=IF([@[OCF/OCF-T-1]]>$EH$3,1,0) 100%
            let OCF正數比較30; // =IF([@[OCF-T-1]]>0,IF([@[OCF/OCF-T-1]]>$EI$3,1,0),0) 30%
            let OCF正數比較50;//=IF([@[OCF-T-1]]>0,IF([@[OCF/OCF-T-1]]>$EJ$3,1,0),0) 50%
            let OCF正數比較22; //=IF([@[OCF-T-1]]>0,IF([@[OCF/OCF-T-1]]>$EK$3,1,0),0) 100%
            let 市值_億; //[#"市值(港元)"]/100000000
            netCash = (s.現金 - s.TL) / s["股數(股)"] * s.單位;
            VDCACurrentRatio = s.VDCA / s.流動負債;
            CE_FCF = s.CE === 0 ? 0 : -(s.CE / s.FCF);
            COE4年正數 = s["COE_Y-3"] > 0 && s["COE_Y-2"] > 0 && s["COE_Y-1"]> 0 && s.COE > 0 ? 1 : "-";
            COE4年連升 = s.COE > s["COE_Y-1"] && s["COE_Y-1"] > s["COE_Y-2"] && s["COE_Y-2"] > s["COE_Y-3"] ? 1 : "-";
            COE最近一年有增長 = s.COE > s.COE_Y_1 ? 1 : "-";
            現價smaller打劫價 = s.現價 < s.打劫價 ? 1 : "-";
            //Profitability////////////////////
            let p = s.CE / s.FCF;
            if (p < 0) {
                Profitability = "-";
            } else if (p < 0.2) {  //20%
                Profitability = 2;
            } else if (p < 0.4) { //40%
                Profitability = 1;
            } else {
                Profitability = "-";
            }
            //////////////////////////////////
            DCA_fcf_cl_0 = (s.流動資產 - (0.5 * s.存貨) - (0.3 * s.應收賬) + s.FCF - s.非流動負債) > 0 ? 1 : "-";
            VDCACurrentRatioBigger1 = VDCACurrentRatio > 1 ? 1 : "-";
            //COERanking//////////
            if (s.COE <= 0) {
                COERanking = -1;
            } else if (s.COE > 0 && s.COE < 0.1) { // COE < 10%
                COERanking = 1;
            } else if (s.COE >= 0.1 && s.COE < 0.2) { // 10% <= COE < 20%
                COERanking = 2;
            } else if (s.COE >= 0.2) { // COE >= 20%
                COERanking = 3;
            }
            //////////////////////////////////
            現價回報率BiggerCoe_1 = s.現價回報率 > s.COE ? 1 : "-";
            NCA_3XFCFBiggerNetCash = (s.NCA + 3 * s.FCF) > netCash ? 1 : "-";
            ////NCA_3XFCFBigger打劫價/////////
            NCA_3XFCFBigger打劫價 = (s.NCA + 3 * s.FCF) > s.打劫價 ? 1 : "-";

            /////////////////////////
            過去3年Turnover都有20增長 = (
                (s["Turnover-T-0"] / s["Turnover-T-1"]) >= (1 + 0.2) &&
                (s["Turnover-T-1"] / s["Turnover-T-2"]) >= (1 + 0.2) &&
                (s["Turnover-T-2"] / s["Turnover-T-3"]) >= (1 + 0.2)
            ) ? 1 : "-";
            過去3年NetProfit都有20增長 = (
                (s["NetProfit-T-0"] / s["NetProfit-T-1"]) >= (1 + 0.2) &&
                (s["NetProfit-T-1"] / s["NetProfit-T-2"]) >= (1 + 0.2) &&
                (s["NetProfit-T-2"] / s["NetProfit-T-3"]) >= (1 + 0.2)
            ) ? 1 : "-";
            // interestBiggerabove = s.每股派息 / s.現價 > 0 ? s.每股派息 / s.現價 : "";
            interestBiggerabove = s.每股派息 / s.現價;
            // console.log(s.股票編號,"...interestBiggerabove", interestBiggerabove)
            // console.log(s.股票編號, "...s.每股派息", s.每股派息)
            // console.log(s.股票編號, "...s.現價", s.現價)



            OCF比較30 = s["OCF/OCF-T-1"] > 0.3 ? 1 : "-";
            OCF比較50 = s["OCF/OCF-T-1"] > 0.5 ? 1 : "-";
            OCF2 = s["OCF/OCF-T-1"] > 1 ? 1 : "-";

            if (s["OCF-T-1"] > 0) {
                if (s["OCF/OCF-T-1"] > 0.3) {
                    OCF正數比較30 = 1;
                } else {
                    OCF正數比較30 = "-";
                }
            } else {
                OCF正數比較30 = "-";
            }

            if (s["OCF-T-1"] > 0) {
                if (s["OCF/OCF-T-1"] > 0.5) {
                    OCF正數比較50 = 1;
                } else {
                    OCF正數比較50 = "-";
                }
            } else {
                OCF正數比較50 = "-";
            }

            if (s["OCF-T-1"] > 0) {
                if (s["OCF/OCF-T-1"] > 1) {
                    OCF正數比較22 = 1;
                } else {
                    OCF正數比較22 = "-";
                }
            } else {
                OCF正數比較22 = "-";
            }
            市值_億 = s["市值(港元)"] / 100000000

            return {
                ...s,
                netCash,
                VDCACurrentRatio,
                CE_FCF,
                COE4年正數,
                COE4年連升,
                COE最近一年有增長,
                現價smaller打劫價,
                Profitability,
                DCA_fcf_cl_0,
                VDCACurrentRatioBigger1,
                COERanking,
                現價回報率BiggerCoe_1,
                NCA_3XFCFBiggerNetCash,
                NCA_3XFCFBigger打劫價,
                過去3年Turnover都有20增長,
                過去3年NetProfit都有20增長,
                interestBiggerabove,
                OCF比較30,
                OCF比較50,
                OCF2,
                OCF正數比較30,
                OCF正數比較50,
                OCF正數比較22,
                市值_億
            }

        });

        return calculatedStocks;

    } else {

        throw new Error("Stock data not provided");

    }

}