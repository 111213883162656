import React from "react";
import { ProgressSpinner } from 'primereact/progressspinner';
import ThemeContext from "./ThemeContext.js";
import { useContext } from "react";


export default function Loading() {

  const { theme } = useContext(ThemeContext);
  let fillColor = "#ffffff"
  if (theme === 'dark') {
    fillColor = "#333333"
  }

  return (

    <div className={`App theme-${theme}`}>


      <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column", height: "100vh" }}>
        <ProgressSpinner style={{ width: '300px', height: '300px' }} strokeWidth="5" fill={fillColor} animationDuration="8s" />
        <h2 className="text-color"><i className="pi pi-cloud-download" style={{ fontSize: '3rem' }}></i> 下載中...請稍候...</h2>
      </div>

    </div>

  )
}




