import { percent, currencyRate, withoutDecimal, hasDecimalFormat } from './formatCommonFunction.js'

export  async function formatVDCAStock(calResult) {
    if (calResult) {
        const formatResult = await calResult.map(c => {
            // let 現價;
            // let 打劫價;
            // let 打劫價調整;
            let netCash;
            // let NCA;
            let 現價回報率_multiply100; //%
            // let 筍價;
            let COE_multiply100; //%
            let 距離打劫價_multiply100 //%
            // let FCF;
            let 市值_億;
            let _52週高;
            let _52週低;
            // let 股數_股;
            // let 報告貨幣匯率;
            // let 每手股數;
            // let 每手價格_港元;
            // let OCF;
            // let CE
            // let E;
            // let VDCA;
            // let TL;
            // let 單位;
            // let 流動資產;
            // let 存貨;
            // let 應收賬;
            // let 現金;
            // let 非流動負債;
            // let 流動負債;
            // let 市值_港元;
            // let NetCash價; //%
            // let 少數股東權益;
            // let 每股自由現金流_港元;
            // let 上限價;
            // let 市場價;
            let VDCACurrentRatio;


            // 現價 = currencyRate(c.現價)
            // 打劫價 = mixFormat(c.打劫價)
            // 打劫價調整 = mixFormat(c.打劫價調整)
            netCash = +((c.netCash).toFixed(2))
            // NCA = mixFormat(c.NCA)
            現價回報率_multiply100 = +((c.現價回報率*100).toFixed(2));
            // 筍價 = mixFormat(c.筍價)
            COE_multiply100 = +((c.COE*100).toFixed(2))
            距離打劫價_multiply100 = +((c.距離打劫價*100).toFixed(2))
            // FCF = mixFormat(c.FCF)
            市值_億 = +(c["市值_億"].toFixed(2))
            _52週高 = c["52週高"] > 0 ? c["52週高"] : ""
            _52週低 = c["52週低"] > 0 ? c["52週低"] : ""
            // 股數_股 = mixFormat(c["股數(股)"])
            // 報告貨幣匯率 = currencyRate(c.報告貨幣匯率)
            // 每手股數 = mixFormat(c.每手股數);
            // 每手價格_港元 = mixFormat(c["每手價格(港元)"]);
            // OCF = mixFormat(c.OCF);
            // CE = mixFormat(c.CE);
            // E = mixFormat(c.E);
            // VDCA = mixFormat(c.VDCA);
            // TL = mixFormat(c.TL);
            // 單位 = mixFormat(c.單位);
            // 流動資產 = mixFormat(c.流動資產);
            // 存貨 = mixFormat(c.存貨);
            // 應收賬 = mixFormat(c.應收賬);
            // 現金 = mixFormat(c.現金)
            // 非流動負債 = mixFormat(c.非流動負債);
            // 流動負債 = mixFormat(c.流動負債);
            // 市值_港元 = withoutDecimal(c["市值(港元)"]);
            // NetCash價 = mixFormat(c["Net Cash價"]);
            // 少數股東權益 = mixFormat(c.少數股東權益);
            // 每股自由現金流_港元 = mixFormat(c["每股自由現金流(港元)"])
            // 上限價 = mixFormat(c.上限價)
            // 市場價 = mixFormat(c.市場價)
            VDCACurrentRatio = c.VDCACurrentRatio.toFixed(0);




            return {
                ...c
                // , 現價
                // , 打劫價
                // , 打劫價調整
                 , netCash
                // , NCA
                , 現價回報率_multiply100 //%
                // , 筍價
                , COE_multiply100 //%
                , 距離打劫價_multiply100 //%
                // , FCF
                , 市值_億
                , _52週高
                , _52週低
                // , 股數_股
                // , 報告貨幣匯率
                // , 每手股數
                // , 每手價格_港元
                // , OCF
                // , CE
                // , E
                // , VDCA
                // , TL
                // , 單位
                // , 流動資產
                // , 存貨
                // , 應收賬
                // , 現金
                // , 非流動負債
                // , 流動負債
                // , 市值_港元
                // , NetCash價 //%
                // , 少數股東權益
                // , 每股自由現金流_港元
                // , 上限價
                // , 市場價
                , VDCACurrentRatio
            }
        })
        return formatResult
    }
}

export const 現價BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.現價);
};

export const 打劫價BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.打劫價);
};

export const 打劫價調整BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.打劫價調整);
};

export const netCashBodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.netCash);
};
export const ncaBodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.NCA);
};

export const 現價回報率BodyTemplate = (rowData) => {
    return percent(rowData.現價回報率);
};

export const 筍價BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.筍價);
};

export const coeBodyTemplate = (rowData) => {
    return percent(rowData.COE);
};

export const 距離打劫價BodyTemplate = (rowData) => {
    return percent(rowData.距離打劫價);
};

export const fcfBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.FCF);
};

export const 市值_億BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.市值_億);
};

export const _52週高BodyTemplate = (rowData) => {
    return currencyRate(rowData._52週高);
};

export const _52週低BodyTemplate = (rowData) => {
    return currencyRate(rowData._52週低);
};

export const 股數_股BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["股數(股)"]);
};

export const 報告貨幣匯率BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.報告貨幣匯率);
};

export const 每手股數BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.每手股數);
};

export const 每手價格_港元BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["每手價格(港元)"]);
};

export const ocfBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.OCF);
};

export const ceBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.CE);
};
export const eBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.E);
};

export const vdcaBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.VDCA);
};

export const tlBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.TL);
};

export const 單位BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.單位);
};

export const 流動資產BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.流動資產);
};

export const 存貨BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.存貨);
};

export const 應收賬BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.應收賬);
};

export const 現金BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.現金);
};

export const 非流動負債BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.非流動負債);
};

export const 流動負債BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.流動負債);
};

export const 市值_港元BodyTemplate = (rowData) => {
    return withoutDecimal(rowData["市值(港元)"]);
};

export const NetCash價BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData["Net Cash價"]);
};

export const 少數股東權益BodyTemplate = (rowData) => {
    return withoutDecimal(rowData.少數股東權益);
};

export const 每股自由現金流_港元BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData["每股自由現金流(港元)"]);
};

export const 上限價BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.上限價);
};

export const 市場價BodyTemplate = (rowData) => {
    return hasDecimalFormat(rowData.市場價);
};

export const VDCACurrentRatioBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.VDCACurrentRatio);
};

export const TotalRankBodyTemplate = (rowData) => {
    return withoutDecimal(rowData.TotalRank);
};