import React from "react";
import "./App.css";
import AppRoutes from "./route";
import { PrimeReactProvider } from "primereact/api";
import { ThemeProvider } from "./components/ThemeContext";

// import "primereact/resources/themes/lara-light-cyan/theme.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import "primeicons/primeicons.css";
import "primereact/resources/primereact.css";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import { Provider } from "react-redux";
import { store } from "./store";

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <PrimeReactProvider>
          <ThemeProvider>
            <AppRoutes />
          </ThemeProvider>
        </PrimeReactProvider>
      </div>
    </Provider>
  );
}

export default App;
