const fetchStockDataUrl = `${process.env.REACT_APP_ST_API_URL}/`;
const fetchDataLatestTimeUrl = `${process.env.REACT_APP_ST_API_URL}/time`;
const audience = process.env.REACT_APP_AUTH0_AUD;

export async function fetchStockData(
  getAccessTokenSilently,
  loginWithRedirect
) {
  try {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: audience,
        // scope: "openid profile email offline_access",
      },
    });

    const res = await fetch(fetchStockDataUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200) {
      return await res.json();
    } else {
      const errorText = await res.text(); // Get error response body if available
      throw new Error(`Request failed with status ${res.status}: ${errorText}`);
    }
  } catch (err) {
    loginWithRedirect();
    console.error("Error fetching stock data:", err);
  }
}

export async function fetchDataLatestTime(
  getAccessTokenSilently,
  loginWithRedirect
) {
  try {
    const token = await getAccessTokenSilently({
      authorizationParams: {
        audience: audience,
        // scope: "openid profile email offline_access",
      },
    });

    const res = await fetch(fetchDataLatestTimeUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (res.status === 200) {
      return await res.json();
    } else {
      throw new Error(`Request failed with status ${res.status}`);
    }
  } catch (err) {
    console.error("Error fetching data latest time:", err);
    loginWithRedirect();
  }
}
