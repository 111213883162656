import { Route, Routes, Navigate } from "react-router-dom";
import HomePage from "./pages/HomePage";
import Profile from "./pages/Profile";
import VDCAPage from "./pages/VDCAPage";
import { useAuth0 } from "@auth0/auth0-react";
import { AuthenticationGuard } from "./components/AuthenticationGuard";
import Loading from "./components/Loading";
import NavBar from "./components/NavBar";
import { useSelector } from "react-redux"
import useAuthListener from "./components/useAuthListener";
import ProtectedRoute from "./components/protectedRoute";


export const routes = {
  home: "/",
  profile: "/profile",
  vdca: "/vdca",
};

export default function AppRoutes() {
  // const { isLoading } = useAuth0();
  const { isLoading } = useSelector(state => state.auth)
  const auth = useAuthListener()

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>

      <NavBar />
      <Routes>

        <Route path={routes.home} element={<HomePage />} />

        {/* <Route
          path={routes.profile}
          element={<AuthenticationGuard component={Profile} />}
        /> */}
        <Route
          path={routes.profile}
          element={<ProtectedRoute auth={auth} component={Profile} />}
        />
        <Route
          path={routes.vdca}
          element={<ProtectedRoute auth={auth} component={VDCAPage} />}
        />
        <Route path="*" element={<Navigate to={routes.home} replace />} />

      </Routes>

    </>
  );
}
