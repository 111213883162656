import React, { useContext } from "react";
import ThemeContext from "./ThemeContext";
import { Button } from "primereact/button";

const ThemeToggle = () => {
  const { toggleTheme } = useContext(ThemeContext);
  //   const { theme, toggleTheme } = useContext(ThemeContext);

  return (
    <Button
      type="button"
      icon="pi pi-sun"
      rounded
      text
      severity="secondary"
      size="small"
      onClick={toggleTheme}
    />

    // <button onClick={toggleTheme}>
    //   Switch to {theme === 'light' ? 'Dark' : 'Light'} Mode
    // </button>
  );
};

export default ThemeToggle;
