import { Button } from "primereact/button";
import moment from "moment";
import "./TitleBar.css"

export default function TitleBar(props) {
    // export default function TitleBar(title, loading, totalRecords, orgLatestTime, refreshingData, clearFilter) {

    return (
        <div className="data-header text-color">
            <div className="d-flex align-item-center">
                <Button
                    type="button"
                    icon="pi pi-refresh"
                    text
                    raised
                    severity="success"
                    onClick={props.refreshingData}
                />
                <Button
                    type="button"
                    icon="pi pi-filter-slash"
                    label="Clear"
                    severity="secondary"
                    raised
                    text
                    onClick={props.clearFilter}
                />

                <span className="text-color">
                    &nbsp;<i className="pi pi-server"></i> Total of records:
                    {!props.loading ? (
                        <>
                            <span> {props.totalRecords}</span>
                        </>
                    ) : (
                        <span>
                            {" "}
                            <i className="pi pi-spin pi-spinner"></i>
                        </span>
                    )}
                </span>
            </div>

            <h2>
                {props.title} <i className="pi pi-chart-line"></i>
            </h2>

            <span className="text-color">
                <i className="pi pi-history"></i> Last updated:
                {!props.loading ? (
                    <>
                        <span>
                            {" "}
                            {moment(props.orgLatestTime).format(
                                "DD MMM YYYY, HH:mm:ss"
                            )}
                        </span>
                    </>
                ) : (
                    <span>
                        {" "}
                        <i className="pi pi-spin pi-spinner"></i>
                    </span>
                )}
            </span>
        </div>
    )




}